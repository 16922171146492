<template>
  <div class="blank-layout-container scrollbar">
    <ModalsContainer />
    <NuxtPwaManifest />

    <CommonsNotifier />
    <slot/>

    <LayoutRestrictionModal />
  </div>
</template>

<script setup lang="ts">
import { ModalsContainer } from "vue-final-modal";

const { isApple } = useDevice();

useHead({
  bodyAttrs: {
    class: isApple ? "" : "designed-scrollbar",
  },
});
</script>

<style lang="scss" scoped>
.blank-layout-container {
  background-color: $uds-white;
  padding: $uds-spacing-2;
  transition: 300ms;

  /* Standard definition as fallback */
  height: calc(100vh - $uds-spacing-2 * 2);
  /* Use dvh to prevent content from hiding under the iOS Safari UI */
  height: calc(100dvh - $uds-spacing-2 * 2);

  width: calc(100vw - 32px);
}
</style>
